import revive_payload_client_oaVMxHGxnZZSy5yU4ADRqwHg0mW1q_2PTLXxD7S7iHE from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jdF_qarmhVcuRqo_A8fkzd_afiD8p2r0NR48u9q_1GI from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NvW6juG3l_RA4JkJ5ND9J8mAKU8Ixcl1utBEzDCi97o from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_X_LNKXEXClgBwhHyVQYtGrc3yylxaMSEzc_6_DIfrhs from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_sass@1_e28c8196d661e8752497515017353afc/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_IxZLtgHuM_AAUpqTullHulkdeI7tUEMhXZlwuvK4x1Y from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BoL_eBYNB2kIPj6DlnVyDMMfl3_eJOhBKAb_LBaeVII from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eWENtsdPVt0HZ_tLb3TZvJBWWogyazDiLf_J7xxHCBg from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6_pSpIa8xdpXF4iSb365_A8wvGp0Z4Jl7_W2Gu4pQug from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_6mNW4xXlitiH50FFkcWJmMbkYHjV5r_xvimTs2Y1Biw from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.3_vue@3.5.13_typescript@5.8.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/www/wwwroot/m.ichi-cake.com/.nuxt/components.plugin.mjs";
import prefetch_client_eCm0gL04xzgMLsOcm9RUT24KGK7I5wtizf6AMy_n1sY from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_29Atf_0JxIRMF_fKB17AgEybTXLo_rLg1m_k8lX4sjM from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/@nuxt+image@1.8.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/plugin.js";
import plugin_aika1Wr_W765GSLeMDzDxRWqppQDDHVgClTWg00Z7so from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.3_vue_7535a39b0647029226d9eaca4ca06629/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_CGLhN19RSG8DNaaO5_ZF4CQrsJqXNifxNd9S92Y_TPY from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ui_bWpFnzoZIVr_VTe90bRH_CuRCfky5OXs4sKVf4So from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/www/wwwroot/m.ichi-cake.com/.nuxt/unocss.mjs";
import plugin_6SOZvOvWV_iSXCylCIyHMG2pSU9K5en2NSd_B91vilk from "/www/wwwroot/m.ichi-cake.com/modules/dayjs/runtime/plugin.ts";
import auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo from "/www/wwwroot/m.ichi-cake.com/plugins/auth.ts";
import error_crtoTsZOJYpzsfZUQLO_Q8nvEvH92fJFjM1mhY4LidY from "/www/wwwroot/m.ichi-cake.com/plugins/error.ts";
import router_client_vVJIZixAP_COSk0LqKRb4d5wiN9ShPbNaqRKThogZrs from "/www/wwwroot/m.ichi-cake.com/plugins/router.client.ts";
import routeMiddleware_PYPtYSa6B2GwDGDMm1P6r2eieoIzzsc2DX2NXjCD2mk from "/www/wwwroot/m.ichi-cake.com/plugins/routeMiddleware.ts";
import index_0_2qzFZk5bcdFpjmAfPE2qs8NXJOWSLo6v5SfzScG0w from "/www/wwwroot/m.ichi-cake.com/plugins/vant/index.ts";
export default [
  revive_payload_client_oaVMxHGxnZZSy5yU4ADRqwHg0mW1q_2PTLXxD7S7iHE,
  unhead_jdF_qarmhVcuRqo_A8fkzd_afiD8p2r0NR48u9q_1GI,
  router_NvW6juG3l_RA4JkJ5ND9J8mAKU8Ixcl1utBEzDCi97o,
  _0_siteConfig_X_LNKXEXClgBwhHyVQYtGrc3yylxaMSEzc_6_DIfrhs,
  payload_client_IxZLtgHuM_AAUpqTullHulkdeI7tUEMhXZlwuvK4x1Y,
  navigation_repaint_client_BoL_eBYNB2kIPj6DlnVyDMMfl3_eJOhBKAb_LBaeVII,
  check_outdated_build_client_eWENtsdPVt0HZ_tLb3TZvJBWWogyazDiLf_J7xxHCBg,
  chunk_reload_client_6_pSpIa8xdpXF4iSb365_A8wvGp0Z4Jl7_W2Gu4pQug,
  plugin_vue3_6mNW4xXlitiH50FFkcWJmMbkYHjV5r_xvimTs2Y1Biw,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_eCm0gL04xzgMLsOcm9RUT24KGK7I5wtizf6AMy_n1sY,
  plugin_29Atf_0JxIRMF_fKB17AgEybTXLo_rLg1m_k8lX4sjM,
  plugin_aika1Wr_W765GSLeMDzDxRWqppQDDHVgClTWg00Z7so,
  switch_locale_path_ssr_CGLhN19RSG8DNaaO5_ZF4CQrsJqXNifxNd9S92Y_TPY,
  i18n_ui_bWpFnzoZIVr_VTe90bRH_CuRCfky5OXs4sKVf4So,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  plugin_6SOZvOvWV_iSXCylCIyHMG2pSU9K5en2NSd_B91vilk,
  auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo,
  error_crtoTsZOJYpzsfZUQLO_Q8nvEvH92fJFjM1mhY4LidY,
  router_client_vVJIZixAP_COSk0LqKRb4d5wiN9ShPbNaqRKThogZrs,
  routeMiddleware_PYPtYSa6B2GwDGDMm1P6r2eieoIzzsc2DX2NXjCD2mk,
  index_0_2qzFZk5bcdFpjmAfPE2qs8NXJOWSLo6v5SfzScG0w
]