
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as cartujvenDpqeuN6jB8N3fR5vVB3yjXYWtXvJNzc1wibDxAMeta } from "/www/wwwroot/m.ichi-cake.com/pages/cart.vue?macro=true";
import { default as userPIum0z0xOmu_unZSrjSbS9OwvaD8VB2eHNmE1XRZOAkMeta } from "/www/wwwroot/m.ichi-cake.com/pages/user.vue?macro=true";
import { default as indexsazFzSBzRHkhQ_45m_kiZg6LdeLenTqKbiprWAZSfeO90Meta } from "/www/wwwroot/m.ichi-cake.com/pages/index.vue?macro=true";
import { default as login_45Zfn6SsNs8cdRsDsJUWEYGu9sPimOKy62_PZ956F9LAMeta } from "/www/wwwroot/m.ichi-cake.com/pages/login.vue?macro=true";
import { default as memberjj_45KDuin9h3FghYl3nIaF_45Uv8B2hoRIBUkVL5sLq8kMMeta } from "/www/wwwroot/m.ichi-cake.com/pages/member.vue?macro=true";
import { default as messageeKXzx5oH6_45Kv5aQ27eQcqj84nSm0Hclf7gl9hKdCzGsMeta } from "/www/wwwroot/m.ichi-cake.com/pages/message.vue?macro=true";
import { default as passwordMt8KFir2bt3iDoxaEOy0BNThYUNHiOMvBRjeiZwq_xMMeta } from "/www/wwwroot/m.ichi-cake.com/pages/password.vue?macro=true";
import { default as registerNPMTmuaw56fgSJeSAHGlXZqk0H88vvhAHrMlktG6pn8Meta } from "/www/wwwroot/m.ichi-cake.com/pages/register.vue?macro=true";
import { default as userInfoQl3rHlCy3Gy_45LjUwTSMHCUt44I964J_45tesJZbC7fLcAMeta } from "/www/wwwroot/m.ichi-cake.com/pages/userInfo.vue?macro=true";
import { default as _91id_93JppfvNAMHBHwVrvRHtqoxdwqsENwd4p7AtUQOU_45218oMeta } from "/www/wwwroot/m.ichi-cake.com/pages/order/[id].vue?macro=true";
import { default as zfb27rxkdwold3CsLj0ug0vuDqfrKeR95Qc0CajBmG9q_45UMeta } from "/www/wwwroot/m.ichi-cake.com/pages/payment/zfb.vue?macro=true";
import { default as _91id_93mpRoz0ICi0W0aKiklpf8s6f25ZSVuxxc5isW9BqKlLQMeta } from "/www/wwwroot/m.ichi-cake.com/pages/recipe/[id].vue?macro=true";
import { default as _91id_93_2TLg5Xio5xF1RtcqOXoypbAsBE1QL_iM_S33mSsJ1IMeta } from "/www/wwwroot/m.ichi-cake.com/pages/product/[id].vue?macro=true";
import { default as indexh_45HEZ6sriHD33TA2R7XS0i3LcpM1AFN0HOnRa2_45llasMeta } from "/www/wwwroot/m.ichi-cake.com/pages/recipe/index.vue?macro=true";
import { default as _91tag_93YU_45wMcRJSoNO_45_SsU7APOIOWITseuwLY6n5AzhagwM0Meta } from "/www/wwwroot/m.ichi-cake.com/pages/hashtag/[tag].vue?macro=true";
import { default as activateRPqZ6ZBCRuojEIrrRGHtumfeH_45wyQ7fWjwDu6FsqBiIMeta } from "/www/wwwroot/m.ichi-cake.com/pages/[type]/activate.vue?macro=true";
import { default as registerUkSr_45lo8Tsl9HShw6dxMhT3S_45zxLVWMGbQ56G0ViwU8Meta } from "/www/wwwroot/m.ichi-cake.com/pages/[type]/register.vue?macro=true";
import { default as _91type_93St3slCy2z3PY047V_TwzkIGAQgx3uFraU_zSJna9z6YMeta } from "/www/wwwroot/m.ichi-cake.com/pages/beMember/[type].vue?macro=true";
import { default as openTZT9ChBUPeVzRmbdz0MukbCujKuRyCAOkpJR0T_Qpa4Meta } from "/www/wwwroot/m.ichi-cake.com/pages/membership/open.vue?macro=true";
import { default as _91term_932PUCvlpWIj43dUkYCLMXxVk5MWlp6oV5ZozHpuYc4UQMeta } from "/www/wwwroot/m.ichi-cake.com/pages/protocol/[term].vue?macro=true";
import { default as helperKP1EACsDEPuvwgqi09QMYzJd93jgk4_45Xa68anoUzEdwMeta } from "/www/wwwroot/m.ichi-cake.com/pages/afterSales/helper.ts?macro=true";
import { default as _91id_93Rb7GuQJMkLdDB2vTmiCUTey4H2Mbt6XTKfDgoSnpmmwMeta } from "/www/wwwroot/m.ichi-cake.com/pages/recipe/form/[id].vue?macro=true";
import { default as _91_46_46_46all_93ZeQ_45M9_HH7PBmMni6cvQvBx4Cp5fMxyoVRZAQdV28GkMeta } from "/www/wwwroot/m.ichi-cake.com/pages/category/[...all].vue?macro=true";
import { default as contactUsAJcg0_P7OHm5x_Ktfc73N5yrf3hgUzt1g1Myh3ZVWH4Meta } from "/www/wwwroot/m.ichi-cake.com/pages/contact/contactUs.vue?macro=true";
import { default as _91info_93lpU_45ddiRLT09Lr1NC4HeEmHB1qVuWgZSH5e2f5QAMSwMeta } from "/www/wwwroot/m.ichi-cake.com/pages/information/[info].vue?macro=true";
import { default as address1Zr9G4GECND6QX7KeWsOT86GpaUgCka5_45s5v1GdX2_45YMeta } from "/www/wwwroot/m.ichi-cake.com/pages/myServices/address.vue?macro=true";
import { default as rewardskzAEwLn3mv69XQsdVlR2UmJIlrDWbMwOA4cUCm0Rd_458Meta } from "/www/wwwroot/m.ichi-cake.com/pages/myServices/rewards.vue?macro=true";
import { default as orderConfirmGx1_45_45MBl_2CkkY1U9P_4Xi7KvtILG0ChQepOLeqtgYYMeta } from "/www/wwwroot/m.ichi-cake.com/pages/order/orderConfirm.vue?macro=true";
import { default as _91status_93HWbC2963u4O_0P3rPfMAwW5FHcc3OhxDcDwsYfykaZEMeta } from "/www/wwwroot/m.ichi-cake.com/pages/order/list/[status].vue?macro=true";
import { default as _91id_93gEWijM_45UQzp_45bqC1QnKvrxGazg7JNUPDJ0PObef5p7QMeta } from "/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/[id].vue?macro=true";
import { default as storeAddresseY9yBk7JW18D3wkXVRdVzzwQFqOpPsa79skyYMV5rJQMeta } from "/www/wwwroot/m.ichi-cake.com/pages/contact/storeAddress.vue?macro=true";
import { default as websiteTeachY_45bY7BEQwKtU8nbMl6Wz5nC6Md8ChgDI6dNycveQIigMeta } from "/www/wwwroot/m.ichi-cake.com/pages/contact/websiteTeach.vue?macro=true";
import { default as DatePicknGjQkOBlT6QYLBvdyXXAjSy_fMQfOGyntBKTdDYDDPgMeta } from "/www/wwwroot/m.ichi-cake.com/pages/recipe/form/DatePick.vue?macro=true";
import { default as _91status_93yPkn94KdQPD1nEKOas3SdTbpwV8ugNO00vF2i2lklDcMeta } from "/www/wwwroot/m.ichi-cake.com/pages/recipe/list/[status].vue?macro=true";
import { default as _91id_93HtHEZyPtJEKdMlRyAPY9OFByQKRHJ_z5L4sVo86YC9EMeta } from "/www/wwwroot/m.ichi-cake.com/pages/afterSales/apply/[id].vue?macro=true";
import { default as addressAddscWPEFuk_cpr0hg8igxArLrMPrncUzk49ZbjpxIJNmoMeta } from "/www/wwwroot/m.ichi-cake.com/pages/myServices/addressAdd.vue?macro=true";
import { default as termswZiJMEpOPNBQh95_45IhmaFbK_45pZjJHhevIz0RIgjg3yMMeta } from "/www/wwwroot/m.ichi-cake.com/pages/shoppingProcess/terms.vue?macro=true";
import { default as _91id_93IEe6sluVqSb1xiycIot6V0_a5o3jmKYlYJiuUTVLgbgMeta } from "/www/wwwroot/m.ichi-cake.com/pages/afterSales/detail/[id].vue?macro=true";
import { default as membersArea7TI17MIKIvE0k6Cbpottgjv1NI1LfAy8oogm_V3f0dYMeta } from "/www/wwwroot/m.ichi-cake.com/pages/myServices/membersArea.vue?macro=true";
import { default as _91id_93K3_r35xQhYk_45NCRxJ8LXbXvL3fu3_45fwTPu1rWMH_45w80Meta } from "/www/wwwroot/m.ichi-cake.com/pages/information/detail/[id].vue?macro=true";
import { default as search6bvtixW8CbBGc0oH08bfsE3_45DzpkVPGRSFkyhgwmf8YMeta } from "/www/wwwroot/m.ichi-cake.com/pages/searchManagement/search.vue?macro=true";
import { default as PopupSettingoJPS8KHAqMdUZGtH8mLQ43KvXEk3ETLMB5924Yr5peAMeta } from "/www/wwwroot/m.ichi-cake.com/pages/recipe/form/PopupSetting.vue?macro=true";
import { default as delivery6nPD9xzkRk2UFbs_45uTIFIaI0q86AIgHABvANi3N9yUAMeta } from "/www/wwwroot/m.ichi-cake.com/pages/shoppingProcess/delivery.vue?macro=true";
import { default as membershipCard3ZAAt0qlGtV57tRyLADysKH_VAotK_455S4L0ejSFiAy0Meta } from "/www/wwwroot/m.ichi-cake.com/pages/membership/membershipCard.vue?macro=true";
import { default as DatePickoOVpx5Rr5sz7BA9MWPR18wqNw0jj_5_45sUlyrRHLGK5kMeta } from "/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/DatePick.vue?macro=true";
import { default as myRecipelzHl1yeH7xHifRg9Gh6WpX6xPjn1uDBC3h_450UnA0pwcMeta } from "/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/myRecipe.vue?macro=true";
import { default as postRecipex_453saECrtsSaRyCWdJ75oCKhqixeXRG2Q_TBRBKglZoMeta } from "/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/postRecipe.vue?macro=true";
import { default as customPicker5DuK0yF8xgP1uejlWDUAs0H28IKEeRRagG1I714sS_8Meta } from "/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/customPicker.vue?macro=true";
import { default as PopupSettingkrP_45W_45s09o2xcBy0PwN5yIcmuKoKSVzCo5jVL2TMPvsMeta } from "/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/PopupSetting.vue?macro=true";
import { default as conversionToolr93k_45gAp8OgWOGJ10gUSHtjeBam7nr_45KqGVb9dh2nvkMeta } from "/www/wwwroot/m.ichi-cake.com/pages/toolManagement/conversionTool.vue?macro=true";
import { default as GetLocationGU1opGGFh2jIueB6RC4xdZyMPUePmXlmoDbBxAznocUMeta } from "/www/wwwroot/m.ichi-cake.com/pages/contact/components/GetLocation.vue?macro=true";
import { default as _91status_93yO158QbC0wMt2ke_gJamE8N6MkiGS_45HbK1qWeLdJsC4Meta } from "/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/list/[status].vue?macro=true";
import { default as customDatePickervaM82tAnCoPdZUunoXAmAC5eMGeexJd7K83cazkNDfgMeta } from "/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/customDatePicker.vue?macro=true";
import { default as onlineuFcQ8dOpPNopvMC_456R7zZMnxKDU6mRZLppVMtXocTI4Meta } from "/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/online.vue?macro=true";
import { default as wholesaleGsnSjS631gKklAcOgC23XpMowmUMmOV8Vw9KlM4YwgcMeta } from "/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/wholesale.vue?macro=true";
import { default as organizationVU2W4hemDIpicJ_4iviG_45L8_aI_kQMHMxiChYaFaq_45oMeta } from "/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/organization.vue?macro=true";
import { default as component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54AsMeta } from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54As } from "/www/wwwroot/m.ichi-cake.com/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._09a1e463bc21da7540347d1570734a24/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/cart.vue")
  },
  {
    name: "cart___tw",
    path: "/cart",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/cart.vue")
  },
  {
    name: "user___en",
    path: "/en/user",
    meta: userPIum0z0xOmu_unZSrjSbS9OwvaD8VB2eHNmE1XRZOAkMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/user.vue")
  },
  {
    name: "user___tw",
    path: "/user",
    meta: userPIum0z0xOmu_unZSrjSbS9OwvaD8VB2eHNmE1XRZOAkMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/user.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/index.vue")
  },
  {
    name: "index___tw",
    path: "/",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/login.vue")
  },
  {
    name: "login___tw",
    path: "/login",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/login.vue")
  },
  {
    name: "member___en",
    path: "/en/member",
    meta: memberjj_45KDuin9h3FghYl3nIaF_45Uv8B2hoRIBUkVL5sLq8kMMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/member.vue")
  },
  {
    name: "member___tw",
    path: "/member",
    meta: memberjj_45KDuin9h3FghYl3nIaF_45Uv8B2hoRIBUkVL5sLq8kMMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/member.vue")
  },
  {
    name: "message___en",
    path: "/en/message",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/message.vue")
  },
  {
    name: "message___tw",
    path: "/message",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/message.vue")
  },
  {
    name: "password___en",
    path: "/en/password",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/password.vue")
  },
  {
    name: "password___tw",
    path: "/password",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/password.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/register.vue")
  },
  {
    name: "register___tw",
    path: "/register",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/register.vue")
  },
  {
    name: "userInfo___en",
    path: "/en/userInfo",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/userInfo.vue")
  },
  {
    name: "userInfo___tw",
    path: "/userInfo",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/userInfo.vue")
  },
  {
    name: "order-id___en",
    path: "/en/order/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/order/[id].vue")
  },
  {
    name: "order-id___tw",
    path: "/order/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/order/[id].vue")
  },
  {
    name: "payment-zfb___en",
    path: "/en/payment/zfb",
    meta: zfb27rxkdwold3CsLj0ug0vuDqfrKeR95Qc0CajBmG9q_45UMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/payment/zfb.vue")
  },
  {
    name: "payment-zfb___tw",
    path: "/payment/zfb",
    meta: zfb27rxkdwold3CsLj0ug0vuDqfrKeR95Qc0CajBmG9q_45UMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/payment/zfb.vue")
  },
  {
    name: "recipe-id___en",
    path: "/en/recipe/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/[id].vue")
  },
  {
    name: "recipe-id___tw",
    path: "/recipe/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/[id].vue")
  },
  {
    name: "product-id___en",
    path: "/en/product/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/product/[id].vue")
  },
  {
    name: "product-id___tw",
    path: "/product/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/product/[id].vue")
  },
  {
    name: "recipe___en",
    path: "/en/recipe",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/index.vue")
  },
  {
    name: "recipe___tw",
    path: "/recipe",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/index.vue")
  },
  {
    name: "hashtag-tag___en",
    path: "/en/hashtag/:tag()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/hashtag/[tag].vue")
  },
  {
    name: "hashtag-tag___tw",
    path: "/hashtag/:tag()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/hashtag/[tag].vue")
  },
  {
    name: "type-activate___en",
    path: "/en/:type()/activate",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/activate.vue")
  },
  {
    name: "type-activate___tw",
    path: "/:type()/activate",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/activate.vue")
  },
  {
    name: "type-register___en",
    path: "/en/:type()/register",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/register.vue")
  },
  {
    name: "type-register___tw",
    path: "/:type()/register",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/register.vue")
  },
  {
    name: "beMember-type___en",
    path: "/en/beMember/:type()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/beMember/[type].vue")
  },
  {
    name: "beMember-type___tw",
    path: "/beMember/:type()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/beMember/[type].vue")
  },
  {
    name: "membership-open___en",
    path: "/en/membership/open",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/membership/open.vue")
  },
  {
    name: "membership-open___tw",
    path: "/membership/open",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/membership/open.vue")
  },
  {
    name: "protocol-term___en",
    path: "/en/protocol/:term()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/protocol/[term].vue")
  },
  {
    name: "protocol-term___tw",
    path: "/protocol/:term()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/protocol/[term].vue")
  },
  {
    name: "afterSales-helper___en",
    path: "/en/afterSales/helper",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/helper.ts")
  },
  {
    name: "afterSales-helper___tw",
    path: "/afterSales/helper",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/helper.ts")
  },
  {
    name: "recipe-form-id___en",
    path: "/en/recipe/form/:id()",
    meta: _91id_93Rb7GuQJMkLdDB2vTmiCUTey4H2Mbt6XTKfDgoSnpmmwMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/form/[id].vue")
  },
  {
    name: "recipe-form-id___tw",
    path: "/recipe/form/:id()",
    meta: _91id_93Rb7GuQJMkLdDB2vTmiCUTey4H2Mbt6XTKfDgoSnpmmwMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/form/[id].vue")
  },
  {
    name: "category-all___en",
    path: "/en/category/:all(.*)*",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/category/[...all].vue")
  },
  {
    name: "category-all___tw",
    path: "/category/:all(.*)*",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/category/[...all].vue")
  },
  {
    name: "contact-contactUs___en",
    path: "/en/contact/contactUs",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/contactUs.vue")
  },
  {
    name: "contact-contactUs___tw",
    path: "/contact/contactUs",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/contactUs.vue")
  },
  {
    name: "information-info___en",
    path: "/en/information/:info()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/information/[info].vue")
  },
  {
    name: "information-info___tw",
    path: "/information/:info()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/information/[info].vue")
  },
  {
    name: "myServices-address___en",
    path: "/en/myServices/address",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/address.vue")
  },
  {
    name: "myServices-address___tw",
    path: "/myServices/address",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/address.vue")
  },
  {
    name: "myServices-rewards___en",
    path: "/en/myServices/rewards",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/rewards.vue")
  },
  {
    name: "myServices-rewards___tw",
    path: "/myServices/rewards",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/rewards.vue")
  },
  {
    name: "order-orderConfirm___en",
    path: "/en/order/orderConfirm",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/order/orderConfirm.vue")
  },
  {
    name: "order-orderConfirm___tw",
    path: "/order/orderConfirm",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/order/orderConfirm.vue")
  },
  {
    name: "order-list-status___en",
    path: "/en/order/list/:status()",
    meta: _91status_93HWbC2963u4O_0P3rPfMAwW5FHcc3OhxDcDwsYfykaZEMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/order/list/[status].vue")
  },
  {
    name: "order-list-status___tw",
    path: "/order/list/:status()",
    meta: _91status_93HWbC2963u4O_0P3rPfMAwW5FHcc3OhxDcDwsYfykaZEMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/order/list/[status].vue")
  },
  {
    name: "afterSales-type-id___en",
    path: "/en/afterSales/type/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/[id].vue")
  },
  {
    name: "afterSales-type-id___tw",
    path: "/afterSales/type/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/[id].vue")
  },
  {
    name: "contact-storeAddress___en",
    path: "/en/contact/storeAddress",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/storeAddress.vue")
  },
  {
    name: "contact-storeAddress___tw",
    path: "/contact/storeAddress",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/storeAddress.vue")
  },
  {
    name: "contact-websiteTeach___en",
    path: "/en/contact/websiteTeach",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/websiteTeach.vue")
  },
  {
    name: "contact-websiteTeach___tw",
    path: "/contact/websiteTeach",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/websiteTeach.vue")
  },
  {
    name: "recipe-form-DatePick___en",
    path: "/en/recipe/form/DatePick",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/form/DatePick.vue")
  },
  {
    name: "recipe-form-DatePick___tw",
    path: "/recipe/form/DatePick",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/form/DatePick.vue")
  },
  {
    name: "recipe-list-status___en",
    path: "/en/recipe/list/:status()",
    meta: _91status_93yPkn94KdQPD1nEKOas3SdTbpwV8ugNO00vF2i2lklDcMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/list/[status].vue")
  },
  {
    name: "recipe-list-status___tw",
    path: "/recipe/list/:status()",
    meta: _91status_93yPkn94KdQPD1nEKOas3SdTbpwV8ugNO00vF2i2lklDcMeta || {},
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/list/[status].vue")
  },
  {
    name: "afterSales-apply-id___en",
    path: "/en/afterSales/apply/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/apply/[id].vue")
  },
  {
    name: "afterSales-apply-id___tw",
    path: "/afterSales/apply/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/apply/[id].vue")
  },
  {
    name: "myServices-addressAdd___en",
    path: "/en/myServices/addressAdd",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/addressAdd.vue")
  },
  {
    name: "myServices-addressAdd___tw",
    path: "/myServices/addressAdd",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/addressAdd.vue")
  },
  {
    name: "shoppingProcess-terms___en",
    path: "/en/shoppingProcess/terms",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/shoppingProcess/terms.vue")
  },
  {
    name: "shoppingProcess-terms___tw",
    path: "/shoppingProcess/terms",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/shoppingProcess/terms.vue")
  },
  {
    name: "afterSales-detail-id___en",
    path: "/en/afterSales/detail/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/detail/[id].vue")
  },
  {
    name: "afterSales-detail-id___tw",
    path: "/afterSales/detail/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/detail/[id].vue")
  },
  {
    name: "myServices-membersArea___en",
    path: "/en/myServices/membersArea",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/membersArea.vue")
  },
  {
    name: "myServices-membersArea___tw",
    path: "/myServices/membersArea",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/myServices/membersArea.vue")
  },
  {
    name: "information-detail-id___en",
    path: "/en/information/detail/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/information/detail/[id].vue")
  },
  {
    name: "information-detail-id___tw",
    path: "/information/detail/:id()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/information/detail/[id].vue")
  },
  {
    name: "searchManagement-search___en",
    path: "/en/searchManagement/search",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/searchManagement/search.vue")
  },
  {
    name: "searchManagement-search___tw",
    path: "/searchManagement/search",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/searchManagement/search.vue")
  },
  {
    name: "recipe-form-PopupSetting___en",
    path: "/en/recipe/form/PopupSetting",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/form/PopupSetting.vue")
  },
  {
    name: "recipe-form-PopupSetting___tw",
    path: "/recipe/form/PopupSetting",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/recipe/form/PopupSetting.vue")
  },
  {
    name: "shoppingProcess-delivery___en",
    path: "/en/shoppingProcess/delivery",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/shoppingProcess/delivery.vue")
  },
  {
    name: "shoppingProcess-delivery___tw",
    path: "/shoppingProcess/delivery",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/shoppingProcess/delivery.vue")
  },
  {
    name: "membership-membershipCard___en",
    path: "/en/membership/membershipCard",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/membership/membershipCard.vue")
  },
  {
    name: "membership-membershipCard___tw",
    path: "/membership/membershipCard",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/membership/membershipCard.vue")
  },
  {
    name: "RecipeManagement-DatePick___en",
    path: "/en/RecipeManagement/DatePick",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/DatePick.vue")
  },
  {
    name: "RecipeManagement-DatePick___tw",
    path: "/RecipeManagement/DatePick",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/DatePick.vue")
  },
  {
    name: "RecipeManagement-myRecipe___en",
    path: "/en/RecipeManagement/myRecipe",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/myRecipe.vue")
  },
  {
    name: "RecipeManagement-myRecipe___tw",
    path: "/RecipeManagement/myRecipe",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/myRecipe.vue")
  },
  {
    name: "RecipeManagement-postRecipe___en",
    path: "/en/RecipeManagement/postRecipe",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/postRecipe.vue")
  },
  {
    name: "RecipeManagement-postRecipe___tw",
    path: "/RecipeManagement/postRecipe",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/postRecipe.vue")
  },
  {
    name: "afterSales-type-customPicker___en",
    path: "/en/afterSales/type/customPicker",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/customPicker.vue")
  },
  {
    name: "afterSales-type-customPicker___tw",
    path: "/afterSales/type/customPicker",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/customPicker.vue")
  },
  {
    name: "RecipeManagement-PopupSetting___en",
    path: "/en/RecipeManagement/PopupSetting",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/PopupSetting.vue")
  },
  {
    name: "RecipeManagement-PopupSetting___tw",
    path: "/RecipeManagement/PopupSetting",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/PopupSetting.vue")
  },
  {
    name: "toolManagement-conversionTool___en",
    path: "/en/toolManagement/conversionTool",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/toolManagement/conversionTool.vue")
  },
  {
    name: "toolManagement-conversionTool___tw",
    path: "/toolManagement/conversionTool",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/toolManagement/conversionTool.vue")
  },
  {
    name: "contact-components-GetLocation___en",
    path: "/en/contact/components/GetLocation",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/components/GetLocation.vue")
  },
  {
    name: "contact-components-GetLocation___tw",
    path: "/contact/components/GetLocation",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/contact/components/GetLocation.vue")
  },
  {
    name: "RecipeManagement-list-status___en",
    path: "/en/RecipeManagement/list/:status()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/list/[status].vue")
  },
  {
    name: "RecipeManagement-list-status___tw",
    path: "/RecipeManagement/list/:status()",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/RecipeManagement/list/[status].vue")
  },
  {
    name: "afterSales-type-customDatePicker___en",
    path: "/en/afterSales/type/customDatePicker",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/customDatePicker.vue")
  },
  {
    name: "afterSales-type-customDatePicker___tw",
    path: "/afterSales/type/customDatePicker",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/afterSales/type/customDatePicker.vue")
  },
  {
    name: "type-components-registerForm-online___en",
    path: "/en/:type()/components/registerForm/online",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/online.vue")
  },
  {
    name: "type-components-registerForm-online___tw",
    path: "/:type()/components/registerForm/online",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/online.vue")
  },
  {
    name: "type-components-registerForm-wholesale___en",
    path: "/en/:type()/components/registerForm/wholesale",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/wholesale.vue")
  },
  {
    name: "type-components-registerForm-wholesale___tw",
    path: "/:type()/components/registerForm/wholesale",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/wholesale.vue")
  },
  {
    name: "type-components-registerForm-organization___en",
    path: "/en/:type()/components/registerForm/organization",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/organization.vue")
  },
  {
    name: "type-components-registerForm-organization___tw",
    path: "/:type()/components/registerForm/organization",
    component: () => import("/www/wwwroot/m.ichi-cake.com/pages/[type]/components/registerForm/organization.vue")
  },
  {
    name: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54AsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54As
  },
  {
    name: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54AsMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54As
  },
  {
    name: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54AsMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54As
  },
  {
    name: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54AsMeta?.name,
    path: "/zh-Hant-sitemap.xml",
    component: component_45stube8ca8jMBZxL4Rdnu6s2MVryaJqhzXyW1N6yUtTf54As
  }
]